
/* eslint-disable no-new */
import { Component, Vue, Watch } from 'vue-property-decorator';
import ReducedLessonList from '@/components/copypage/ReducedLessonList.vue';
import LessonDraggable from '@/utils/lesson-draggable';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import ld from 'lodash';

@Component({
  props: {
    items: {
      type: Array
    },
    lessons: {
      type: Array
    },
    copyFrom: {
      type: Boolean
    },
    allClasses: {
      type: Boolean
    }
  },
  components: {
    ReducedLessonList
  }
})
export default class CopyUnitList extends Vue {
  headers = [
    { text: this.$t('classLabel'), value: 'class', width: '15%', sortable: false, divider: true },
    { text: this.$t('unitLabel'), value: 'unitTitle', width: '55%', sortable: false, divider: true },
    { text: this.$t('startLabel'), value: 'unitStart', width: '15%', sortable: false, divider: true },
    { text: this.$t('endLabel'), value: 'unitEnd', width: '15%', sortable: false },
    { text: '', value: 'data-table-expand', sortable: false }
  ]

  selectedItem = false;
  selectedRow: Array<any> = [];
  tableItems: Array<any> = [];
  maxTableHeight = 90;
  hoverNum = 0;

  draggables: Array<any> = [];

  get filteredHeaders() {
    return this.headers.filter((h) => h.value !== 'class' || this.$props.allClasses);
  }

  getDisplayDate(date: string, showYear: boolean) {
    if (CommonUtils.hasText(date)) {
      return DateTimeUtils.formatToDisplay(date, showYear);
    }
    return '';
  }

  @Watch('lessons')
  updateLessons() {
    this.tableItems = ld.cloneDeep(this.$props.items);
    this.$props.lessons.map((l: any) => {
      if (l.unitId !== 0) {
        const associatedUnit = this.tableItems.find((u: any) => u.unitId === l.unitId);
        if (associatedUnit) {
          if (associatedUnit.lessonList) {
            associatedUnit.lessonList.push(l);
          } else {
            Vue.set(associatedUnit, 'lessonList', [l]);
          }
        }
      }
    })
    this.$nextTick(() => {
      this.initDraggable();
    })
  }

  getUnitTitle(item: any) {
    return item.unitNum + ' - ' + item.unitTitle + ' (' + (item.lessonList ? item.lessonList.length : 0) + ' Lessons)';
  }

  rowSelected() {
    return (this.selectedRow.length > 0);
  }

  getSelectedUnitId() {
    if (this.selectedRow.length > 0) {
      return this.selectedRow[0].unitId;
    } else {
      return null;
    }
  }

  getSelectedUnit() {
    if (this.selectedRow.length > 0) {
      return this.selectedRow[0];
    } else {
      return null;
    }
  }

  getLessonIds() {
    if (this.selectedRow.length > 0) {
      return this.selectedRow[0].lessonList.map((l: any) => { return l.lessonId });
    }
  }

  initDraggable() {
    for (const i in this.tableItems) {
      const itemRef: any = this.$refs['lessonItem' + i];
      if (!itemRef || itemRef.length === 0) {
        continue;
      }
      const html = `<div class="d-flex pa-2 white--text font-weight-bold" style="width: 200px; background-color: ${this.$vuetify.theme.currentTheme.primary};">
                    <div class="ml-2">
                    <div>${CommonUtils.htmlEscape(this.getUnitTitle(this.tableItems[i]))}</div>
                    </div>
                    </div>`;

      this.draggables.push(new LessonDraggable(
        itemRef[0],
        {
          data: () => ld.cloneDeep(this.tableItems[i]),
          options: {
            helper: 'clone',
            appendTo: '.copyLessonsBox',
            disabled: false,
            scroll: false,
            cursorAt: { top: 0, left: 0 },
            cursor: 'grab'
          },
          listeners: {
            'drag:start': (event: any) => {
              const copyPage = document.querySelector('.copy') as HTMLElement;
              copyPage.classList.add('prevent-select');
              event.helper.innerHTML = html;
            },
            'drag:stop': () => {
              const copyPage = document.querySelector('.copy') as HTMLElement;
              copyPage.classList.remove('prevent-select');
            }
          }
        }
      ));
    }
  }

  hoverEnterEdit(key: any) {
    this.selectedItem = key;
  }

  hoverExitEdit() {
    this.selectedItem = false;
  }

  mounted() {
    this.resizeTable();
  }

  resizeTable() {
    const tableRef: any = this.$refs.table
    this.maxTableHeight = window.innerHeight - tableRef.$el.getBoundingClientRect().top - 20;
  }

  created() {
    this.$eventBus.$on('resize', this.resizeTable);
  }

  beforeDestroy() {
    this.draggables.forEach((d: any) => {
      try {
        d.destroy();
      } catch (e) {
      }
    });
    this.draggables = [];
  }

  destroyed() {
    this.$eventBus.$off('resize', this.resizeTable);
  }
}
