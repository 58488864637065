var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-data-table", {
            ref: "table",
            staticClass: "lesson-list-table",
            attrs: {
              outlined: "",
              headers: _vm.headers,
              items: _vm.localTableItems,
              "item-key": "lessonId",
              "items-per-page": -1,
              "hide-default-footer": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.date`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "td",
                        {
                          style: {
                            backgroundColor: _vm.getDateBgColor(item.date),
                            color: _vm.getDateTextColor(item.date),
                          },
                          on: {
                            mouseenter: function ($event) {
                              return _vm.hoverEnterEdit(item.lessonId)
                            },
                            mouseleave: _vm.hoverExitEdit,
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { align: "center", justify: "center" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.stringifyDate(item.date, true)) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: `item.lessonTitle`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "td",
                        {
                          on: {
                            mouseenter: function ($event) {
                              return _vm.hoverEnterEdit(item.lessonId)
                            },
                            mouseleave: _vm.hoverExitEdit,
                          },
                        },
                        [
                          _vm.isEmptyLesson(item)
                            ? _c(
                                "span",
                                {
                                  staticClass: "ml-1",
                                  style: {
                                    color: _vm.$vuetify.theme.currentTheme.info,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("emptyLessonLabel")))]
                              )
                            : _c("lesson-list-item", {
                                staticStyle: { cursor: "default" },
                                attrs: {
                                  dayObject: _vm.getLocalDayObject(
                                    item,
                                    item.dayObject
                                  ),
                                  showDetails: true,
                                },
                              }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }