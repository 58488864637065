


























































































































/* eslint-disable no-new */
import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ld from 'lodash';
import DateTimeUtils from '@/utils/date-time-utils';
import LessonListItem from '@/components/lessonlists/LessonListItem.vue';
import SectionActions from '@/components/plans/card/SectionActions.vue';
import SectionAttachments from '@/components/plans/card/SectionAttachments.vue';
import SectionGroup from '@/components/plans/card/SectionGroup.vue';
import SectionStandards from '@/components/plans/card/SectionStandards.vue';
import SectionStatuses from '@/components/plans/card/SectionStatuses.vue';
import SectionText from '@/components/plans/card/SectionText.vue';
import SectionTitle from '@/components/plans/card/SectionTitle.vue';
import LessonDraggable from '@/utils/lesson-draggable';
import LessonDroppable from '@/utils/lesson-droppable';
import moment from 'moment';

const settings = namespace('settings');

@Component({
  props: {
    items: {
      type: Array
    },
    copyFrom: {
      type: Boolean,
      default: true
    },
    showDate: {
      type: Boolean,
      default: true
    },
    allClasses: {
      type: Boolean
    }
  },
  components: {
    LessonListItem,
    SectionActions,
    SectionAttachments,
    SectionGroup,
    SectionStandards,
    SectionStatuses,
    SectionText,
    SectionTitle
  }
})
export default class CopyLessonList extends Vue {
  localRefreshKey = CommonUtils.generateUUID();
  tableLessonBankItems: Array<any> = [];
  selectedItem = false;
  hoverNum = 0;
  hoverClass = 0;
  maxTableHeight = 45;
  initLimit = 800;
  headers = [
    { text: '#', value: 'extraLesson', width: 80, sortable: false, divider: true },
    { text: this.$t('dateLabel'), value: 'date', width: 165, align: 'center', sortable: false, divider: true },
    { text: this.$t('classLabel'), value: 'class', width: 150, sortable: false, divider: true },
    { text: this.$t('lessonLabel'), value: 'lessonTitle', sortable: false },
    { text: '', value: 'data-table-expand', sortable: false }
  ]

  @settings.Getter('getDateStyling')
  dateStyling!: any;

  @Watch('items')
  updateTableItems(items:any) {
    this.tableLessonBankItems = ld.cloneDeep(items);
    this.clearDragDrop();
    this.initialize();
    this.$nextTick(() => {
      if (this.localTableItems.length < this.initLimit) {
        this.initDraggable();
      }
    })
  }

  beforeDestroy() {
    this.clearDragDrop();
  }

  clearDragDrop() {
    for (const i in this.droppables) {
      try { this.droppables[i].destroy() } catch (e) {}
      delete this.droppables[i]
    }
    for (const i in this.draggables) {
      try { this.draggables[i].destroy() } catch (e) {}
      delete this.draggables[i];
    }
    this.droppables = [];
    this.draggables = [];

    for (const i in this.droppablesMap) {
      try { this.droppablesMap[i].destroy() } catch (e) {}
      delete this.droppablesMap[i]
    }
    for (const i in this.draggablesMap) {
      try { this.draggablesMap[i].destroy() } catch (e) {}
      delete this.draggablesMap[i]
    }
  }

  goToOptions: any = {
    duration: 300,
    easing: 'easeInOutCubic',
    offset: 45
  }

  scrollTop() {
    const tableRef: any = this.$refs.table
    const container = { container: tableRef.$el }
    this.goToOptions = Object.assign(this.goToOptions, container)
    if (document.getElementsByClassName('topRow').length > 0) {
      this.$vuetify.goTo('.topRow', this.goToOptions);
    }
  }

  scrollToToday() {
    const tableRef: any = this.$refs.table
    const container = { container: tableRef.$el }
    this.goToOptions = Object.assign(this.goToOptions, container)
    if (document.getElementsByClassName('todayRow' + this.localRefreshKey).length > 0) {
      this.$vuetify.goTo('.todayRow' + this.localRefreshKey, this.goToOptions);
    }
  }

  markToday() {
    const currentDate = DateTimeUtils.currentDateAddDays(0);
    for (const i in this.tableLessonBankItems) {
      if (this.tableLessonBankItems[i].date === currentDate || DateTimeUtils.isThisDateAfterThatDate(this.tableLessonBankItems[i].date, currentDate)) {
        this.tableLessonBankItems[i].currentDate = true;
        return;
      }
    }
  }

  clearHighlight() {
    this.hoverNum = 0;
  }

  get filteredHeaders() {
    return this.headers.filter((h: any) => {
      if (h.value === 'date') {
        return this.$props.showDate;
      } else if (h.value === 'extraLesson') {
        return !this.$props.allClasses;
      } else if (h.value === 'class') {
        return this.$props.allClasses;
      }
      return true;
    })
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  @Watch('showDate')
  initialize() {
    if (this.$props.showDate) {
      this.markToday();
    }
  }

  droppablesMap: any = {};
  draggablesMap: any = {};
  mouseOver(item: any, index: any) {
    if (this.localTableItems.length < this.initLimit) {
      return;
    }
    const itemRef: any = this.$refs['lessonItem' + index];
    let html = '';
    if (this.$props.showDate) {
      html = `<div class="d-flex pa-2 white--text font-weight-bold" style="width: 200px; background-color: ${this.$vuetify.theme.currentTheme.primary}';">
                  <div class="ml-2">
                  <div>${item.className}</div>
                  <div>${DateTimeUtils.formatToShow(item.date)}</div>
                  </div>
                  </div>`;
    } else {
      html = `<div class="d-flex pa-2 white--text font-weight-bold" style="width: 200px; background-color: ${this.$vuetify.theme.currentTheme.primary};">
                  <div class="ml-2">
                  <div>${item.className}</div>
                  </div>
                  </div>`;
    }

    if (this.$props.copyFrom && !this.draggablesMap[index]) {
      this.draggablesMap[index] = new LessonDraggable(
        itemRef[0],
        {
          data: () => ld.cloneDeep(item),
          options: {
            helper: 'clone',
            appendTo: '.copyLessonsBox',
            scroll: false,
            cursorAt: { top: 0, left: 0 },
            cursor: 'grab'
          },
          listeners: {
            'drag:start': (event: any) => {
              document.body.classList.add('prevent-select');
              event.helper.innerHTML = html;
            },
            'drag:stop': () => {
              document.body.classList.remove('prevent-select');
            }
          }
        }
      )
    }
    if (!this.$props.copyFrom && !this.droppablesMap[index]) {
      this.droppablesMap[index] = new LessonDroppable(
        itemRef[0],
        {
          data: () => ld.cloneDeep(item),
          options: {},
          listeners: {
            'droppable:over': (event: any) => {
              this.hoverNum = event.droppable.data().lessonNum;
              this.hoverClass = event.droppable.data().classId;
            },
            'droppable:out': () => {
              this.hoverNum = 0;
            },
            'droppable:drop': (event: any) => {
              const from = event.draggable.data();
              const to = event.droppable.data();
              if (!from.lessonNum) {
                this.$emit('dragDropUnit', from, to);
              } else {
                this.$emit('dragDropLesson', from, to);
              }
            }
          }
        }
      )
    }
  }

  draggables: Array<any> = [];
  droppables: Array<any> = [];
  initDraggable() {
    for (const i in this.localTableItems) {
      const itemRef: any = this.$refs['lessonItem' + i];
      if (!itemRef || itemRef.length === 0) {
        continue;
      }
      let html = '';
      if (this.$props.showDate) {
        html = `<div class="d-flex pa-2 white--text font-weight-bold" style="width: 200px; background-color: ${this.$vuetify.theme.currentTheme.primary};">
                    <div class="ml-2">
                    <div>${this.localTableItems[i].className}</div>
                    <div>${DateTimeUtils.formatToShow(this.localTableItems[i].date)}</div>
                    </div>
                    </div>`;
      } else {
        html = `<div class="d-flex pa-2 white--text font-weight-bold" style="width: 200px; background-color: ${this.$vuetify.theme.currentTheme.primary};">
                    <div class="ml-2">
                    <div>${this.localTableItems[i].className}</div>
                    </div>
                    </div>`;
      }

      if (this.$props.copyFrom) {
        this.draggables.push(new LessonDraggable(
          itemRef[0],
          {
            data: () => ld.cloneDeep(this.localTableItems[i]),
            options: {
              helper: 'clone',
              appendTo: '.copyLessonsBox',
              scroll: false,
              cursorAt: { top: 0, left: 0 },
              cursor: 'grab'
            },
            listeners: {
              'drag:start': (event: any) => {
                document.body.classList.add('prevent-select');
                event.helper.innerHTML = html;
              },
              'drag:stop': () => {
                document.body.classList.remove('prevent-select');
              }
            }
          }
        ))
      }
      if (!this.$props.copyFrom) {
        this.droppables.push(new LessonDroppable(
          itemRef[0],
          {
            data: () => ld.cloneDeep(this.localTableItems[i]),
            options: {},
            listeners: {
              'droppable:over': (event: any) => {
                this.hoverNum = event.droppable.data().lessonNum;
                this.hoverClass = event.droppable.data().classId;
              },
              'droppable:out': () => {
                this.hoverNum = 0;
              },
              'droppable:drop': (event: any) => {
                const from = event.draggable.data();
                const to = event.droppable.data();
                if (!from.lessonNum) {
                  this.$emit('dragDropUnit', from, to);
                } else {
                  this.$emit('dragDropLesson', from, to);
                }
              }
            }
          }
        ))
      }
    }
  }

  get currentDateBackgroundColor() {
    return CommonUtils.getColor(this.getValue('currentDateBackgroundColor', this.dateStyling.currentDateBackgroundColor || 'inherit'));
  }

  get currentDateTextColor() {
    return CommonUtils.getColor(this.getValue('currentDateTextColor', this.dateStyling.currentDateTextColor || 'inherit'));
  }

  get currentDate() {
    const currentDate = new Date();
    const currentDay = (currentDate.getDate() < 10 ? '0' : '') + currentDate.getDate();
    const nCurrentMonth = currentDate.getMonth() + 1;
    const currentMonth = (nCurrentMonth < 10 ? '0' : '') + nCurrentMonth;
    const currentDatemmddyyyy = currentMonth + '/' + currentDay + '/' + currentDate.getFullYear();
    return currentDatemmddyyyy;
  }

  get updatedSettings(): any {
    return this.$store.state.settings.updatedSettings;
  }

  get localTableItems () {
    return this.tableLessonBankItems
  }

  set localTableItems(val:any) {
    this.tableLessonBankItems = val;
  }

  get stringifyDate() {
    return DateTimeUtils.stringifyDate;
  }

  get moment() {
    return moment;
  }

  getLocalDayObject(data:any, dayObject:any) {
    const rawDayObject = ld.cloneDeep(data);
    delete rawDayObject.dayObject;
    const obj = {
      ...dayObject,
      rawDayObject: rawDayObject
    }
    return obj
  }

  isUntitledLesson(item:any) {
    return this.hasLessonId(item) && CommonUtils.hasNoText(item.lessonTitle);
  }

  isEmptyLesson(item:any) {
    return !this.hasLessonId(item);
  }

  hasLessonId(item:any) {
    if (+item.lessonId > 0) {
      return true;
    } else {
      return false;
    }
  }

  getDateBgColor(date:any) {
    if (date === this.currentDate) {
      return this.currentDateBackgroundColor;
    }
  }

  getDateTextColor(date:any) {
    if (date === this.currentDate) {
      return this.currentDateTextColor;
    }
  }

  getValue(key: string, defaultValue: any) {
    if (Object.prototype.hasOwnProperty.call(this.updatedSettings, key)) {
      return this.updatedSettings[key];
    }
    return defaultValue;
  }

  hoverEnterEdit(key: any) {
    this.selectedItem = key;
  }

  hoverExitEdit() {
    this.selectedItem = false;
  }

  mounted() {
    this.tableLessonBankItems = this.$props.items;
    this.resizeTable();
  }

  resizeTable() {
    const tableRef: any = this.$refs.table
    this.maxTableHeight = window.innerHeight - tableRef.$el.getBoundingClientRect().top - 20;
  }

  created() {
    this.$eventBus.$on('resize', this.resizeTable);
    this.initialize();
  }

  destroyed() {
    this.$eventBus.$off('resize', this.resizeTable);
  }

  lessonTextField(item:any): string {
    let textField = '';
    for (const content of item.dayObject.sections) {
      if (content.hide === 'N' && content.type !== 'section-statuses' && content.items) {
        for (const cItem of content.items) {
          if (CommonUtils.hasText(cItem.text)) {
            textField = this.firstHundredChar(cItem.text);
            break;
          }
        }
        if (CommonUtils.hasText(textField)) {
          break;
        }
      }
    }
    return textField;
  }

  unitSectionsTextField(item:any): string {
    let textField = '';
    if (item.unit) {
      for (const content of item.unit.contents) {
        if (CommonUtils.hasText(content.value)) {
          textField = this.firstHundredChar(content.value);
          break;
        }
      }
    }
    return textField;
  }

  linkedUnitSectionsTextField(item:any): string {
    let textField = '';
    if (item.linkedUnit) {
      for (const content of item.linkedUnit.contents) {
        if (CommonUtils.hasText(content.value)) {
          textField = this.firstHundredChar(content.value);
          break;
        }
      }
    }
    return textField;
  }

  firstHundredChar(html:string) {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(html, 'text/html');
    const textContent = parsedHtml.body.textContent || '';
    return textContent.substring(0, 100);
  }
}
