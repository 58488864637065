
import { Component, Vue, Prop } from 'vue-property-decorator';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';

@Component
export default class CopyList extends Vue {
    @Prop()
    items!: Array<any>;

    @Prop({ type: String })
    itemType!: string;

    @Prop({ type: Boolean })
    isSelectable!: boolean;

    @Prop({ type: Boolean })
    allClasses!: boolean;

    selected = [];
    selectedItem = false;
    maxTableHeight = 45;

    hoverEnterEdit(key: any) {
      this.selectedItem = key;
    }

    hoverExitEdit() {
      this.selectedItem = false;
    }

    getDisplayDate(date: string, showYear: boolean) {
      if (CommonUtils.hasText(date)) {
        return DateTimeUtils.formatToDisplay(date, showYear);
      }
      return '';
    }

    localCheckboxValue(item: any, isSelected: boolean) {
      if (item.source === 'gc' && item.isClasswork) {
        return false;
      } else {
        return isSelected;
      }
    }

    get typeText() {
      switch (this.itemType) {
        case 'AS': return this.$t('assessmentsLabel')
        case 'AN': return this.$t('assignmentsLabel')
        case 'C': return this.$t('classesLabel')
        case 'S': return this.$t('studentsLabel')
        case 'LB': return this.$t('lessonBanksLabel')
      }
    }

    get getItemKey() {
      switch (this.itemType) {
        case 'AS': return 'assessmentId'
        case 'AN': return 'assignmentId'
        case 'C': return 'cId'
        case 'S': return 'studentId'
        case 'LB': return 'cId'
      }
      return '';
    }

    get getHeaders() {
      const selectHeader = (this.isSelectable) ? [{ text: '', value: 'data-table-select', sortable: false, divider: true }] : [];
      const classHeader = (this.allClasses) ? [{ text: this.$t('classLabel').toString(), value: 'className', width: 80, sortable: false, divider: true }] : [];
      return selectHeader.concat(classHeader).concat(this.getTypeHeaders);
    }

    get getTypeHeaders(): Array<any> {
      switch (this.itemType) {
        case 'AS': return [
          { text: this.$t('assessmentLabel'), value: 'assessmentTitle', width: 160, sortable: false, divider: true },
          { text: this.$t('startLabel'), value: 'assessmentStart', width: 80, sortable: false, divider: true },
          { text: this.$t('endLabel'), value: 'assessmentEnd', width: 80, sortable: false, divider: false }
        ]
        case 'AN': return [
          { text: this.$t('assignmentLabel'), value: 'assignmentTitle', width: 160, sortable: false, divider: true },
          { text: this.$t('startLabel'), value: 'assignmentStart', width: 80, sortable: false, divider: true },
          { text: this.$t('endLabel'), value: 'assignmentEnd', width: 80, sortable: false, divider: false }
        ]
        case 'C': return [
          { text: this.$t('classLabel'), value: 'cN', width: 160, sortable: false, divider: true },
          { text: this.$t('startLabel'), value: 'cSd', width: 80, sortable: false, divider: true },
          { text: this.$t('endLabel'), value: 'cEd', width: 80, sortable: false, divider: false }
        ]
        case 'S': return [
          { text: this.$t('studentIDLabel'), value: 'code', width: 40, sortable: false, divider: true },
          { text: this.$t('nameLabel'), value: 'name', width: 80, sortable: false, divider: true },
          { text: this.$t('emailLabel'), value: 'email', width: 160, sortable: false, divider: false }
        ]
        case 'LB': return [
          { text: this.$t('lessonBankLabel'), value: 'cN', width: 160, sortable: false, divider: false }
        ]
      }
      return [];
    }

    mounted() {
      this.resizeTable();
    }

    resizeTable() {
      const tableRef: any = this.$refs.table
      this.maxTableHeight = window.innerHeight - tableRef.$el.getBoundingClientRect().top - 20;
    }

    created() {
      this.$eventBus.$on('resize', this.resizeTable);
    }

    destroyed() {
      this.$eventBus.$off('resize', this.resizeTable);
    }

    getSelectedItems(): Array<any> {
      return this.selected
    }

    getSelectedItemsIds(): Array<any> {
      return this.selected.map((s: any) => s[this.getItemKey]);
    }
}
