var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "enclosed-box" },
    [
      _c(
        "v-row",
        { staticClass: "lesson-list-title" },
        [
          _vm.copyFrom
            ? _c("div", { staticClass: "ml-2 mt-2" }, [
                _vm._v(_vm._s(_vm.$t("fromLessonsLabel")))
              ])
            : _c("div", { staticClass: "ml-2 mt-2" }, [
                _vm._v(_vm._s(_vm.$t("toLessonsLabel")))
              ]),
          _c("v-spacer"),
          _vm.showDate
            ? _c("v-btn", { on: { click: _vm.scrollToToday } }, [
                _vm._v(_vm._s(_vm.$t("todayLabel")))
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "copyLessonsBox mb-2" },
        [
          _c("v-data-table", {
            ref: "table",
            staticClass: "lesson-list-table",
            style: { "max-height": _vm.maxTableHeight + "px" },
            attrs: {
              headers: _vm.filteredHeaders,
              items: _vm.localTableItems,
              "item-key": "lessonId",
              "show-expand": "",
              "single-expand": "",
              "items-per-page": -1,
              "hide-default-footer": "",
              "fixed-header": ""
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function(ref) {
                  var items = ref.items
                  var isExpanded = ref.isExpanded
                  var expand = ref.expand
                  return [
                    items.length > 0
                      ? _c(
                          "tbody",
                          _vm._l(items, function(item, index) {
                            return _c(
                              "tr",
                              {
                                key: index,
                                ref: "lessonItem" + index,
                                refInFor: true,
                                staticClass:
                                  "lesson lesson-draggable lesson-droppable outlined",
                                class: [
                                  _vm.copyFrom ? "grabbable" : "",
                                  item.currentDate
                                    ? "todayRow" + _vm.localRefreshKey
                                    : "",
                                  index == 0 ? "topRow" : ""
                                ],
                                on: {
                                  mouseover: function($event) {
                                    return _vm.mouseOver(item, index)
                                  }
                                }
                              },
                              [
                                !_vm.allClasses
                                  ? _c(
                                      "td",
                                      {
                                        class: {
                                          onHover:
                                            _vm.hoverNum == item.lessonNum
                                        },
                                        staticStyle: {
                                          "border-right": "1px solid #dddddd"
                                        },
                                        on: {
                                          mouseenter: function($event) {
                                            return _vm.hoverEnterEdit(
                                              item.lessonId
                                            )
                                          },
                                          mouseleave: _vm.hoverExitEdit
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              align: "center",
                                              justify: "center"
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.lessonNum))
                                            ])
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.showDate
                                  ? _c(
                                      "td",
                                      {
                                        class: {
                                          onHover:
                                            _vm.allClasses &&
                                            _vm.hoverNum == item.lessonNum &&
                                            _vm.hoverClass == item.classId
                                        },
                                        staticStyle: {
                                          "border-right": "1px solid #dddddd"
                                        },
                                        style: {
                                          backgroundColor: _vm.getDateBgColor(
                                            item.date
                                          ),
                                          color: _vm.getDateTextColor(item.date)
                                        },
                                        on: {
                                          mouseenter: function($event) {
                                            return _vm.hoverEnterEdit(
                                              item.lessonId
                                            )
                                          },
                                          mouseleave: _vm.hoverExitEdit
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              align: "center",
                                              justify: "center"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.stringifyDate(
                                                    item.date,
                                                    true
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.allClasses
                                  ? _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "border-right": "1px solid #dddddd"
                                        },
                                        on: {
                                          mouseenter: function($event) {
                                            return _vm.hoverEnterEdit(
                                              item.lessonId
                                            )
                                          },
                                          mouseleave: _vm.hoverExitEdit
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              align: "center",
                                              justify: "center"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.className) + " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "td",
                                  {
                                    on: {
                                      mouseenter: function($event) {
                                        return _vm.hoverEnterEdit(item.lessonId)
                                      },
                                      mouseleave: _vm.hoverExitEdit
                                    }
                                  },
                                  [
                                    isExpanded(item)
                                      ? _c(
                                          "div",
                                          { staticClass: "ml-3" },
                                          [
                                            _c("lesson-list-item", {
                                              staticStyle: {
                                                cursor: "default"
                                              },
                                              attrs: {
                                                dayObject: _vm.getLocalDayObject(
                                                  item,
                                                  item.dayObject
                                                ),
                                                showDetails: true
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _c("div", { staticClass: "ml-3" }, [
                                          _vm.isUntitledLesson(item)
                                            ? _c("div", [
                                                _vm.hasText(
                                                  _vm.lessonTextField(item)
                                                )
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.firstHundredChar(
                                                            _vm.lessonTextField(
                                                              item
                                                            )
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  : _vm.hasText(
                                                      item.linkedLessonTitle
                                                    )
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.linkedLessonTitle
                                                        )
                                                      )
                                                    ])
                                                  : _vm.hasText(
                                                      item.linkedLessonText
                                                    )
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.firstHundredChar(
                                                            item.linkedLessonText
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  : _vm.hasText(item.unitTitle)
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(item.unitTitle)
                                                      )
                                                    ])
                                                  : item.unitId > 0 &&
                                                    _vm.hasText(
                                                      _vm.unitSectionsTextField(
                                                        item
                                                      )
                                                    )
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.firstHundredChar(
                                                            _vm.unitSectionsTextField(
                                                              item
                                                            )
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  : item.linkedUnit &&
                                                    _vm.hasText(
                                                      item.linkedUnit.unitTitle
                                                    )
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.linkedUnit
                                                            .unitTitle
                                                        )
                                                      )
                                                    ])
                                                  : item.linkedUnitId > 0 &&
                                                    _vm.hasText(
                                                      _vm.linkedUnitSectionsTextField(
                                                        item
                                                      )
                                                    )
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.firstHundredChar(
                                                            _vm.linkedUnitSectionsTextField(
                                                              item
                                                            )
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "untitledLessonsLabel"
                                                          )
                                                        )
                                                      )
                                                    ])
                                              ])
                                            : _vm.isEmptyLesson(item)
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "ml-1",
                                                  style: {
                                                    color:
                                                      _vm.$vuetify.theme
                                                        .currentTheme.info
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("emptyLessonLabel")
                                                    )
                                                  )
                                                ]
                                              )
                                            : _c("strong", [
                                                _vm._v(_vm._s(item.lessonTitle))
                                              ])
                                        ])
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    on: {
                                      mouseenter: function($event) {
                                        return _vm.hoverEnterEdit(item.lessonId)
                                      },
                                      mouseleave: _vm.hoverExitEdit
                                    }
                                  },
                                  [
                                    !_vm.isEmptyLesson(item)
                                      ? _c(
                                          "div",
                                          [
                                            !isExpanded(item)
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return expand(item)
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "fa-regular fa-angle-down"
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "v-icon",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return expand(
                                                          item,
                                                          false
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "fa-solid fa-angle-up"
                                                    )
                                                  ]
                                                )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      : _c("tbody", [
                          _c(
                            "tr",
                            { staticClass: "v-data-table__empty-wrapper" },
                            [
                              _c(
                                "td",
                                {
                                  attrs: { colspan: _vm.filteredHeaders.length }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("noDataAvailableLabel")) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }