
import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import LessonServices from '@/services/lesson-services';
import ld from 'lodash';
import DateTimeUtils from '@/utils/date-time-utils';
import draggable from 'vuedraggable';
import LessonListItem from '@/components/lessonlists/LessonListItem.vue';
import SectionActions from '@/components/plans/card/SectionActions.vue';
import SectionAttachments from '@/components/plans/card/SectionAttachments.vue';
import SectionGroup from '@/components/plans/card/SectionGroup.vue';
import SectionStandards from '@/components/plans/card/SectionStandards.vue';
import SectionStatuses from '@/components/plans/card/SectionStatuses.vue';
import SectionText from '@/components/plans/card/SectionText.vue';
import SectionTitle from '@/components/plans/card/SectionTitle.vue';
const settings = namespace('settings');
const lessonlists = namespace('lessonlists');
const classes = namespace('classes');
@Component({
  props: {
    items: {
      type: Array
    },
    showDate: {
      type: Boolean,
      default: true
    }
  },
  components: {
    draggable,
    LessonListItem,
    SectionActions,
    SectionAttachments,
    SectionGroup,
    SectionStandards,
    SectionStatuses,
    SectionText,
    SectionTitle
  }
})
export default class LessonListsTableReduced extends Vue {
  tableLessonBankItems: any[] = [];
  selectedItem = false;

  headers:any[] = [];

  @lessonlists.Getter('getSelectedLessonBankClassId')
  selectedLessonBankClassId!: number;

  @lessonlists.Getter('getCurrentSelectedClassColor')
  currentSelectedClassColor!: any;

  @settings.Getter('getSharedClasses')
  sharedClass!: any[];

  @settings.Getter('getDateStyling')
  dateStyling!: any;

  @classes.Getter('getClassUnits')
  getClassUnits!: (classId: number) => any[];

  @Watch('items')
  updateTableItems(items:any) {
    this.tableLessonBankItems = items;
    this.initialize();
  }

  @Watch('showDate')
  initialize() {
    if (this.$props.showDate) {
      this.headers = [
        {
          text: this.$t('dateLabel'),
          value: 'date',
          width: '20%',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('lessonLabel'),
          value: 'lessonTitle',
          width: '80%',
          sortable: false
        }
      ]
    } else {
      this.headers = [
        {
          text: this.$t('lessonLabel'),
          value: 'lessonTitle',
          width: '100%',
          sortable: false
        }
      ]
    }
  }

  get currentDateBackgroundColor() {
    return CommonUtils.getColor(this.getValue('currentDateBackgroundColor', this.dateStyling.currentDateBackgroundColor || 'inherit'));
  }

  get currentDateTextColor() {
    return CommonUtils.getColor(this.getValue('currentDateTextColor', this.dateStyling.currentDateTextColor || 'inherit'));
  }

  get currentDate() {
    const currentDate = new Date();
    const currentDay = (currentDate.getDate() < 10 ? '0' : '') + currentDate.getDate();
    const nCurrentMonth = currentDate.getMonth() + 1;
    const currentMonth = (nCurrentMonth < 10 ? '0' : '') + nCurrentMonth;
    const currentDatemmddyyyy = currentMonth + '/' + currentDay + '/' + currentDate.getFullYear();
    return currentDatemmddyyyy;
  }

  get updatedSettings(): any {
    return this.$store.state.settings.updatedSettings;
  }

  get localTableItems () {
    return this.tableLessonBankItems
  }

  set localTableItems(val:any) {
    this.tableLessonBankItems = val;
  }

  get stringifyDate() {
    return DateTimeUtils.stringifyDate;
  }

  getLocalDayObject(data:any, dayObject:any) {
    const rawDayObject = ld.cloneDeep(data);
    delete rawDayObject.dayObject;
    const obj = {
      ...dayObject,
      rawDayObject: rawDayObject
    }
    return obj
  }

  isUntitledLesson(item:any) {
    return this.hasLessonId(item) && CommonUtils.hasNoText(item.lessonTitle);
  }

  isEmptyLesson(item:any) {
    return !this.hasLessonId(item);
  }

  hasLessonId(item:any) {
    if (+item.lessonId > 0) {
      return true;
    } else {
      return false;
    }
  }

  hasData(lesson:any) {
    return LessonServices.hasLessonData(lesson);
  }

  getDateBgColor(date:any) {
    if (date === this.currentDate) {
      return this.currentDateBackgroundColor;
    }
  }

  getDateTextColor(date:any) {
    if (date === this.currentDate) {
      return this.currentDateTextColor;
    }
  }

  getValue(key: string, defaultValue: any) {
    if (Object.prototype.hasOwnProperty.call(this.updatedSettings, key)) {
      return this.updatedSettings[key];
    }
    return defaultValue;
  }

  hoverEnterEdit(key: any) {
    this.selectedItem = key;
  }

  hoverExitEdit() {
    this.selectedItem = false;
  }

  mounted() {
    this.tableLessonBankItems = this.$props.items;
  }

  created() {
    this.initialize();
  }
}
