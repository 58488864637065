var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "list-title" }, [
        _vm._v(
          _vm._s(
            _vm.isSelectable ? _vm.$t("fromLabelBold") : _vm.$t("toLabelBold")
          ) +
            " " +
            _vm._s(_vm.typeText)
        )
      ]),
      _c("v-data-table", {
        ref: "table",
        staticClass: "table",
        style: { "max-height": _vm.maxTableHeight + "px" },
        attrs: {
          "fixed-header": "",
          items: _vm.items,
          "item-key": _vm.getItemKey,
          headers: _vm.getHeaders,
          "show-select": _vm.isSelectable,
          "items-per-page": -1,
          "hide-default-footer": ""
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.data-table-select",
              fn: function(ref) {
                var item = ref.item
                var isSelected = ref.isSelected
                var select = ref.select
                return [
                  _c("v-simple-checkbox", {
                    attrs: {
                      value: _vm.localCheckboxValue(item, isSelected),
                      readonly: item.source === "gc" && item.isClasswork,
                      disabled: item.source === "gc" && item.isClasswork,
                      tabindex: "0"
                    },
                    on: {
                      input: function($event) {
                        return select($event)
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return select($event)
                      }
                    }
                  })
                ]
              }
            },
            {
              key: "item.assessmentStart",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "td",
                    {
                      staticStyle: {
                        cursor: "pointer",
                        "border-right": "1px solid #dddddd"
                      },
                      on: {
                        mouseenter: function($event) {
                          return _vm.hoverEnterEdit(item[_vm.getItemKey])
                        },
                        mouseleave: _vm.hoverExitEdit
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.getDisplayDate(item.assessmentStart, true))
                        )
                      ])
                    ]
                  )
                ]
              }
            },
            {
              key: "item.assessmentEnd",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "td",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        mouseenter: function($event) {
                          return _vm.hoverEnterEdit(item[_vm.getItemKey])
                        },
                        mouseleave: _vm.hoverExitEdit
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.getDisplayDate(item.assessmentEnd, true))
                        )
                      ])
                    ]
                  )
                ]
              }
            },
            {
              key: "item.assignmentStart",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "td",
                    {
                      staticStyle: {
                        cursor: "pointer",
                        "border-right": "1px solid #dddddd"
                      },
                      on: {
                        mouseenter: function($event) {
                          return _vm.hoverEnterEdit(item[_vm.getItemKey])
                        },
                        mouseleave: _vm.hoverExitEdit
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.getDisplayDate(item.assignmentStart, true))
                        )
                      ])
                    ]
                  )
                ]
              }
            },
            {
              key: "item.assignmentEnd",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "td",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        mouseenter: function($event) {
                          return _vm.hoverEnterEdit(item[_vm.getItemKey])
                        },
                        mouseleave: _vm.hoverExitEdit
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.getDisplayDate(item.assignmentEnd, true))
                        )
                      ])
                    ]
                  )
                ]
              }
            },
            {
              key: "item.cSd",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "td",
                    {
                      staticStyle: {
                        cursor: "pointer",
                        "border-right": "1px solid #dddddd"
                      },
                      on: {
                        mouseenter: function($event) {
                          return _vm.hoverEnterEdit(item[_vm.getItemKey])
                        },
                        mouseleave: _vm.hoverExitEdit
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.getDisplayDate(item.cSd, true)))
                      ])
                    ]
                  )
                ]
              }
            },
            {
              key: "item.cEd",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "td",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        mouseenter: function($event) {
                          return _vm.hoverEnterEdit(item[_vm.getItemKey])
                        },
                        mouseleave: _vm.hoverExitEdit
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.getDisplayDate(item.cEd, true)))
                      ])
                    ]
                  )
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }