var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-row", { staticClass: "unit-list-title" }, [
        _c("div", [_vm._v(_vm._s(_vm.$t("fromUnitsLabel")))]),
      ]),
      _c(
        "v-row",
        { staticClass: "copyLessonsBox mb-2" },
        [
          _c("v-data-table", {
            ref: "table",
            staticClass: "unit-list-table",
            style: { "max-height": _vm.maxTableHeight + "px" },
            attrs: {
              outlined: "",
              headers: _vm.filteredHeaders,
              items: _vm.tableItems,
              "item-key": "unitId",
              "items-per-page": -1,
              "hide-default-footer": "",
              "show-expand": "",
              "single-expand": "",
              "fixed-header": "",
              "show-select": "",
              "single-select": "",
            },
            scopedSlots: _vm._u(
              [
                _vm.items.length > 0
                  ? {
                      key: "body",
                      fn: function ({
                        items,
                        isSelected,
                        select,
                        isExpanded,
                        expand,
                      }) {
                        return _vm._l(items, function (item, index) {
                          return _c("tbody", { key: index }, [
                            _c(
                              "tr",
                              {
                                ref: "lessonItem" + index,
                                refInFor: true,
                                staticClass: "lesson-draggable",
                              },
                              [
                                _c(
                                  "td",
                                  [
                                    !isSelected(item)
                                      ? _c(
                                          "v-icon",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return select(item)
                                              },
                                            },
                                          },
                                          [_vm._v("fa-regular fa-square")]
                                        )
                                      : _c(
                                          "v-icon",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return select(item, false)
                                              },
                                            },
                                          },
                                          [_vm._v("fa-solid fa-square-check")]
                                        ),
                                  ],
                                  1
                                ),
                                _vm.allClasses
                                  ? _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "border-right": "1px solid #dddddd",
                                        },
                                        on: {
                                          mouseenter: function ($event) {
                                            return _vm.hoverEnterEdit(
                                              item.unitId
                                            )
                                          },
                                          mouseleave: _vm.hoverExitEdit,
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _c("span", [
                                            _vm._v(_vm._s(item.className)),
                                          ]),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "border-right": "1px solid #dddddd",
                                    },
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.hoverEnterEdit(item.unitId)
                                      },
                                      mouseleave: _vm.hoverExitEdit,
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.getUnitTitle(item))),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "border-right": "1px solid #dddddd",
                                    },
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.hoverEnterEdit(item.unitId)
                                      },
                                      mouseleave: _vm.hoverExitEdit,
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDisplayDate(
                                              item.unitStart,
                                              true
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.hoverEnterEdit(item.unitId)
                                      },
                                      mouseleave: _vm.hoverExitEdit,
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDisplayDate(
                                              item.unitEnd,
                                              true
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "td",
                                  [
                                    !isExpanded(item)
                                      ? _c(
                                          "v-icon",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return expand(item)
                                              },
                                            },
                                          },
                                          [_vm._v("fa-regular fa-angle-down")]
                                        )
                                      : _c(
                                          "v-icon",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return expand(item, false)
                                              },
                                            },
                                          },
                                          [_vm._v("fa-solid fa-angle-up")]
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            isExpanded(item)
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        colspan: _vm.headers.length + 1,
                                      },
                                    },
                                    [
                                      _c("reduced-lesson-list", {
                                        attrs: { items: item.lessonList },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        })
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedRow,
              callback: function ($$v) {
                _vm.selectedRow = $$v
              },
              expression: "selectedRow",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }