var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    { staticClass: "copy pt-6", attrs: { color: "#ffffff" } },
    [
      _c(
        "v-card",
        { staticClass: "copy rounded-0", attrs: { flat: "" } },
        [
          _c(
            "validation-observer",
            { ref: "dateObserver" },
            [
              _c(
                "v-row",
                { staticClass: "d-flex flex-nowrap mx-2" },
                [
                  _c(
                    "v-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.$vuetify.breakpoint.smAndDown ||
                            _vm.copySide == 0,
                          expression:
                            "!$vuetify.breakpoint.smAndDown || copySide == 0"
                        }
                      ],
                      attrs: {
                        cols: _vm.$vuetify.breakpoint.smAndDown ? 12 : 6
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "header ml-0 mr-0" },
                        [
                          _c("v-col", { attrs: { cols: "3" } }),
                          _c("v-col", { attrs: { cols: "6" } }, [
                            _c(
                              "div",
                              {
                                class: _vm.$vuetify.breakpoint.smAndDown
                                  ? "pt-2"
                                  : ""
                              },
                              [_vm._v(_vm._s(_vm.$t("copyFromLabel")))]
                            )
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _vm.$vuetify.breakpoint.smAndDown
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.copySide = 1
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [_vm._v("fal fa-arrow-right")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "d-flex flex-nowrap" },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: { cols: _vm.showCopyFromTeacher ? 6 : 12 }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: _vm.$t("typeLabel"),
                                  items: _vm.copyFromTypeList,
                                  "item-text": "text",
                                  "return-object": "",
                                  outlined: "",
                                  "hide-details": "auto",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.copyFromType,
                                  callback: function($$v) {
                                    _vm.copyFromType = $$v
                                  },
                                  expression: "copyFromType"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.showCopyFromTeacher
                            ? _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: _vm.$t("fromLabel"),
                                      items: _vm.copyFromTeacherList,
                                      "item-value": "id",
                                      "item-text": "name",
                                      "return-object": "",
                                      outlined: "",
                                      "hide-details": "auto",
                                      dense: "",
                                      rules: _vm.copyFromError
                                        ? [
                                            function() {
                                              return _vm.copyFromError
                                            }
                                          ]
                                        : []
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function(data) {
                                            return [
                                              data.item.id == 1
                                                ? _c(
                                                    "v-list-item",
                                                    _vm._b(
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.importCSV.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          }
                                                        }
                                                      },
                                                      "v-list-item",
                                                      data.attrs,
                                                      false
                                                    ),
                                                    [
                                                      _vm._v(
                                                        _vm._s(data.item.name)
                                                      )
                                                    ]
                                                  )
                                                : data.item.id == 2
                                                ? _c(
                                                    "v-list-item",
                                                    _vm._b(
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.addTeacher.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          }
                                                        }
                                                      },
                                                      "v-list-item",
                                                      data.attrs,
                                                      false
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { small: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fal fa-circle-plus"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.item.name
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : data.item.fromPermissions ==
                                                  false
                                                ? _c(
                                                    "v-list-item",
                                                    _vm._g(
                                                      _vm._b(
                                                        {},
                                                        "v-list-item",
                                                        data.attrs,
                                                        false
                                                      ),
                                                      data.on
                                                    ),
                                                    [
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(data.item.name)
                                                        )
                                                      ]),
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { small: "" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              $event.stopPropagation()
                                                              return _vm.editTeacher(
                                                                data.item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("fal fa-pen")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "v-list-item",
                                                    _vm._g(
                                                      _vm._b(
                                                        {},
                                                        "v-list-item",
                                                        data.attrs,
                                                        false
                                                      ),
                                                      data.on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        _vm._s(data.item.name)
                                                      )
                                                    ]
                                                  )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2780419318
                                    ),
                                    model: {
                                      value: _vm.copyFromTeacher,
                                      callback: function($$v) {
                                        _vm.copyFromTeacher = $$v
                                      },
                                      expression: "copyFromTeacher"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      (_vm.showCopyFromYear || _vm.showCopyFromClass) &&
                      !_vm.isFromDistrict
                        ? _c(
                            "v-row",
                            { staticClass: "d-flex flex-nowrap" },
                            [
                              _vm.showCopyFromYear
                                ? _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: _vm.showCopyFromClass ? 6 : 12
                                      }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.localCopyFromYearList,
                                          label: _vm.$t("yearLabel"),
                                          outlined: "",
                                          "hide-details": "auto",
                                          dense: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selection",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.yearName) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _vm.hasText(item.type)
                                                    ? _c(
                                                        "span",
                                                        {
                                                          class: [
                                                            "year-item-type",
                                                            "year-item-type-" +
                                                              item.type.toLowerCase()
                                                          ]
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.type)
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.yearName)
                                                    )
                                                  ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1719478007
                                        ),
                                        model: {
                                          value: _vm.copyFromYear,
                                          callback: function($$v) {
                                            _vm.copyFromYear = $$v
                                          },
                                          expression: "copyFromYear"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.showCopyFromClass
                                ? _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: _vm.showCopyFromYear ? 6 : 12
                                      }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: _vm.$t("classLabel"),
                                          items: _vm.filteredCopyFromClassList,
                                          "item-text": "className",
                                          "return-object": "",
                                          outlined: "",
                                          "hide-details": "auto",
                                          "item-value": "classId",
                                          dense: ""
                                        },
                                        model: {
                                          value: _vm.copyFromClass,
                                          callback: function($$v) {
                                            _vm.copyFromClass = $$v
                                          },
                                          expression: "copyFromClass"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showCopyFromBank && _vm.bankNotHidden
                        ? _c(
                            "v-row",
                            { staticClass: "d-flex flex-nowrap" },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: _vm.$t("bankLabel"),
                                      items: _vm.copyFromBankList,
                                      "item-text": "className",
                                      "item-value": "classId",
                                      "return-object": "",
                                      outlined: "",
                                      "hide-details": "auto",
                                      dense: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "selection",
                                          fn: function(data) {
                                            return [
                                              !data.item.bankSharedOrigin ||
                                              !_vm.copyFromSelf
                                                ? _c("span", [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          data.item.className
                                                        )
                                                      )
                                                    ])
                                                  ])
                                                : _c("span", [
                                                    data.item.districtId !==
                                                      0 && data.item.districtId
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "copySelectBank tDistrictBank"
                                                          },
                                                          [_vm._v("D")]
                                                        )
                                                      : data.item
                                                          .bankSharedOrigin &&
                                                        data.item.schoolId !==
                                                          0 &&
                                                        data.item.schoolId
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "copySelectBank tSchoolBank"
                                                          },
                                                          [_vm._v("S")]
                                                        )
                                                      : _vm._e(),
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.item.className
                                                          )
                                                      )
                                                    ])
                                                  ])
                                            ]
                                          }
                                        },
                                        {
                                          key: "item",
                                          fn: function(data) {
                                            return [
                                              !data.item.bankSharedOrigin ||
                                              !_vm.copyFromSelf
                                                ? _c("span", [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          data.item.className
                                                        )
                                                      )
                                                    ])
                                                  ])
                                                : _c("span", [
                                                    data.item.districtId !==
                                                      0 && data.item.districtId
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "copySelectBank tDistrictBank"
                                                          },
                                                          [_vm._v("D")]
                                                        )
                                                      : data.item
                                                          .bankSharedOrigin &&
                                                        data.item.schoolId !==
                                                          0 &&
                                                        data.item.schoolId
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "copySelectBank tSchoolBank"
                                                          },
                                                          [_vm._v("S")]
                                                        )
                                                      : _vm._e(),
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.item.className
                                                          )
                                                      )
                                                    ])
                                                  ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2202790620
                                    ),
                                    model: {
                                      value: _vm.copyFromBank,
                                      callback: function($$v) {
                                        _vm.copyFromBank = $$v
                                      },
                                      expression: "copyFromBank"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("span", [
                              _vm._v(_vm._s(_vm.copyFromType.message))
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm.copyFromType.code == "C"
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("multi-select-field", {
                                    attrs: {
                                      "hide-details": "auto",
                                      dense: "",
                                      label: _vm.$t("classItemsLabel"),
                                      suffix: "Items",
                                      items: _vm.classItems
                                    },
                                    model: {
                                      value: _vm.selectedClassItems,
                                      callback: function($$v) {
                                        _vm.selectedClassItems = $$v
                                      },
                                      expression: "selectedClassItems"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.copyFromType.code == "U"
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("multi-select-field", {
                                    attrs: {
                                      "hide-details": "auto",
                                      dense: "",
                                      label:
                                        _vm.$t("unitLabel") +
                                        " " +
                                        _vm.$t("itemsLabel"),
                                      suffix: "Items",
                                      items: _vm.getUnitTabs
                                    },
                                    model: {
                                      value: _vm.selectedUnitItems,
                                      callback: function($$v) {
                                        _vm.selectedUnitItems = $$v
                                      },
                                      expression: "selectedUnitItems"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.copyFromType.code == "L"
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: _vm.$vuetify.breakpoint.smAndDown
                                      ? 4
                                      : 5
                                  }
                                },
                                [
                                  !_vm.fromAllClasses
                                    ? _c("v-select", {
                                        attrs: {
                                          label: _vm.$t("unitLabel"),
                                          items: _vm.copyFromLessonUnitsList,
                                          "item-text": "title",
                                          "return-object": "",
                                          outlined: "",
                                          "hide-details": "auto",
                                          dense: ""
                                        },
                                        model: {
                                          value: _vm.copyFromLessonUnits,
                                          callback: function($$v) {
                                            _vm.copyFromLessonUnits = $$v
                                          },
                                          expression: "copyFromLessonUnits"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.copyFromBank == null
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-6 mb-4",
                                      attrs: {
                                        cols: _vm.$vuetify.breakpoint.smAndDown
                                          ? 8
                                          : 7
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "ma-0 pa-0",
                                              attrs: {
                                                cols: _vm.$vuetify.breakpoint
                                                  .smAndDown
                                                  ? 8
                                                  : 10
                                              }
                                            },
                                            [
                                              _c("pb-date-time-range-field", {
                                                attrs: {
                                                  hideDetails: "auto",
                                                  dense: true,
                                                  label: _vm.$t("dateLabel"),
                                                  start:
                                                    _vm.copyFromLessonStartDate,
                                                  end:
                                                    _vm.copyFromLessonEndDate,
                                                  initialStart:
                                                    _vm.getClassStart,
                                                  initialEnd: _vm.getClassEnd,
                                                  autoPopulateRange: false,
                                                  rules: [
                                                    function() {
                                                      return _vm.lessonRangeDateError
                                                    }
                                                  ]
                                                },
                                                on: {
                                                  "update:start": [
                                                    function($event) {
                                                      _vm.copyFromLessonStartDate = $event
                                                    },
                                                    _vm.validateLessonRangeDate
                                                  ],
                                                  "update:end": [
                                                    function($event) {
                                                      _vm.copyFromLessonEndDate = $event
                                                    },
                                                    _vm.validateLessonRangeDate
                                                  ]
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "ma-0 pa-0",
                                              attrs: {
                                                cols: _vm.$vuetify.breakpoint
                                                  .smAndDown
                                                  ? 4
                                                  : 2
                                              }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "px-0",
                                                  staticStyle: {
                                                    "margin-top": "1px"
                                                  },
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click:
                                                      _vm.loadLessonTimeRange
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("showLabel")
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-col",
                                    { attrs: { cols: "7" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "d-flex flex-nowrap no-gutters"
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "5" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "startPositionLabel"
                                                  ),
                                                  "single-line": "",
                                                  dense: "",
                                                  outlined: "",
                                                  rules: _vm.copyStartPosError,
                                                  "hide-details": "auto",
                                                  type: "number"
                                                },
                                                model: {
                                                  value:
                                                    _vm.copyFromLessonStartPosition,
                                                  callback: function($$v) {
                                                    _vm.copyFromLessonStartPosition = $$v
                                                  },
                                                  expression:
                                                    "copyFromLessonStartPosition"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "5" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "endPositionLabel"
                                                  ),
                                                  "single-line": "",
                                                  dense: "",
                                                  outlined: "",
                                                  rules: _vm.copyEndPosError,
                                                  "hide-details": "auto",
                                                  type: "number"
                                                },
                                                model: {
                                                  value:
                                                    _vm.copyFromLessonEndPosition,
                                                  callback: function($$v) {
                                                    _vm.copyFromLessonEndPosition = $$v
                                                  },
                                                  expression:
                                                    "copyFromLessonEndPosition"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "2" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "px-0 mt-1",
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.loadLessonPositionRange(
                                                        false
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("showLabel")
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.$vuetify.breakpoint.smAndDown ||
                            _vm.copySide == 1,
                          expression:
                            "!$vuetify.breakpoint.smAndDown || copySide == 1"
                        }
                      ],
                      staticClass: "mr-8",
                      attrs: {
                        cols: _vm.$vuetify.breakpoint.smAndDown ? 12 : 6
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "header ml-0" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _vm.$vuetify.breakpoint.smAndDown
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.copySide = 0
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [_vm._v("fal fa-arrow-left")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "6" } }, [
                            _c(
                              "div",
                              {
                                class: _vm.$vuetify.breakpoint.smAndDown
                                  ? "pt-2"
                                  : ""
                              },
                              [_vm._v(_vm._s(_vm.$t("copyToLabel")))]
                            )
                          ]),
                          _c("v-col", { attrs: { cols: "3" } })
                        ],
                        1
                      ),
                      _vm.copyToError !== ""
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      staticClass: "mb-0",
                                      attrs: {
                                        dense: "",
                                        border: "left",
                                        type: "error"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.copyToError) + " ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        { staticClass: "d-flex flex-nowrap" },
                        [
                          _vm.showCopyToClass
                            ? _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: _vm.$t("classLabel"),
                                      items: _vm.filteredCopyToClassList,
                                      "item-text": "cN",
                                      "item-value": "cId",
                                      "return-object": "",
                                      outlined: "",
                                      "hide-details": "auto",
                                      dense: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function(data) {
                                            return [
                                              data.item.cId == 0
                                                ? _c(
                                                    "v-list-item",
                                                    _vm._b(
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.addClass.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          }
                                                        }
                                                      },
                                                      "v-list-item",
                                                      data.attrs,
                                                      false
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { small: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fal fa-circle-plus"
                                                          )
                                                        ]
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(data.item.cN)
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "v-list-item",
                                                    _vm._g(
                                                      _vm._b(
                                                        {},
                                                        "v-list-item",
                                                        data.attrs,
                                                        false
                                                      ),
                                                      data.on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        _vm._s(data.item.cN)
                                                      )
                                                    ]
                                                  )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3215851444
                                    ),
                                    model: {
                                      value: _vm.copyToClass,
                                      callback: function($$v) {
                                        _vm.copyToClass = $$v
                                      },
                                      expression: "copyToClass"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showCopyToYearBank
                            ? _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: _vm.$t("yearBankLabel"),
                                      items: _vm.copyToYearBankList,
                                      "item-text": "yearName",
                                      "return-object": "",
                                      outlined: "",
                                      "hide-details": "auto",
                                      dense: ""
                                    },
                                    model: {
                                      value: _vm.copyToYearBank,
                                      callback: function($$v) {
                                        _vm.copyToYearBank = $$v
                                      },
                                      expression: "copyToYearBank"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showCopyToBank
                            ? _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: _vm.$t("bankLabel"),
                                      items: _vm.copyToBankList,
                                      "item-text": "className",
                                      "item-value": "classId",
                                      "return-object": "",
                                      outlined: "",
                                      "hide-details": "auto",
                                      dense: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "selection",
                                          fn: function(data) {
                                            return [
                                              !data.item.bankSharedOrigin
                                                ? _c("span", [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          data.item.className
                                                        )
                                                      )
                                                    ])
                                                  ])
                                                : _c("span", [
                                                    data.item.districtId !==
                                                      0 && data.item.districtId
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "copySelectBank tDistrictBank"
                                                          },
                                                          [_vm._v("D")]
                                                        )
                                                      : data.item.schoolId !==
                                                          0 &&
                                                        data.item.schoolId
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "copySelectBank tSchoolBank"
                                                          },
                                                          [_vm._v("S")]
                                                        )
                                                      : _vm._e(),
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.item.className
                                                          )
                                                      )
                                                    ])
                                                  ])
                                            ]
                                          }
                                        },
                                        {
                                          key: "item",
                                          fn: function(data) {
                                            return [
                                              data.item.classId == 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.addLessonBank.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { small: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fal fa-circle-plus"
                                                          )
                                                        ]
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "addLessonBankLabel"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                : data.item.classId == 1
                                                ? _c(
                                                    "span",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.addSchoolLessonBank.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { small: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fal fa-circle-plus"
                                                          )
                                                        ]
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "addSchoolLessonBankLabel"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                : !data.item.bankSharedOrigin
                                                ? _c("span", [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          data.item.className
                                                        )
                                                      )
                                                    ])
                                                  ])
                                                : _c("span", [
                                                    data.item.districtId !==
                                                      0 && data.item.districtId
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "copySelectBank tDistrictBank"
                                                          },
                                                          [_vm._v("D")]
                                                        )
                                                      : data.item.schoolId !==
                                                          0 &&
                                                        data.item.schoolId
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "copySelectBank tSchoolBank"
                                                          },
                                                          [_vm._v("S")]
                                                        )
                                                      : _vm._e(),
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.item.className
                                                          )
                                                      )
                                                    ])
                                                  ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1269376285
                                    ),
                                    model: {
                                      value: _vm.copyToBank,
                                      callback: function($$v) {
                                        _vm.copyToBank = $$v
                                      },
                                      expression: "copyToBank"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _vm.copyFromType.code == "L" ||
                          _vm.copyFromType.code == "U"
                            ? _c(
                                "v-col",
                                [
                                  _c(
                                    "v-radio-group",
                                    {
                                      staticClass: "mt-0",
                                      attrs: { column: "", required: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "div",
                                                  { staticClass: "mb-2" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "placeLessonsLabel"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        3549091355
                                      ),
                                      model: {
                                        value: _vm.lessonCopyType,
                                        callback: function($$v) {
                                          _vm.lessonCopyType = $$v
                                        },
                                        expression: "lessonCopyType"
                                      }
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label:
                                            _vm.appendDate != ""
                                              ? _vm.$t(
                                                  "appendLessonsLabelDated",
                                                  { date: _vm.appendDate }
                                                )
                                              : _vm.$t("appendLessonsLabel"),
                                          value: "APPEND"
                                        }
                                      }),
                                      _c("v-radio", {
                                        attrs: { value: "INSERT" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "span",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "insertLessonsLabel"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "max-width": "200px"
                                                      }
                                                    },
                                                    [
                                                      !_vm.copyToBank
                                                        ? _c("pb-date-field", {
                                                            attrs: {
                                                              disabled:
                                                                _vm.lessonCopyType ==
                                                                "APPEND",
                                                              label: _vm.$t(
                                                                "insertDateLabel"
                                                              ),
                                                              dense: "",
                                                              rules:
                                                                _vm.insertDateError,
                                                              hideDetails:
                                                                "auto"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.lessonInsertDate,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.lessonInsertDate = $$v
                                                              },
                                                              expression:
                                                                "lessonInsertDate"
                                                            }
                                                          })
                                                        : _c("v-text-field", {
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "insertPosition"
                                                              ),
                                                              "single-line": "",
                                                              dense: "",
                                                              outlined: "",
                                                              "hide-details":
                                                                "auto",
                                                              type: "number"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.lessonInsertPosition,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.lessonInsertPosition = $$v
                                                              },
                                                              expression:
                                                                "lessonInsertPosition"
                                                            }
                                                          })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          2678439949
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { align: "center" } },
                            [
                              _vm.copyFromType.code != "L" ||
                              !_vm.fromAllClasses ||
                              _vm.toAllClasses ||
                              _vm.copyToBank
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: { click: _vm.startCopy }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("copyLabel")) +
                                          " " +
                                          _vm._s(_vm.copyFromType.text)
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.showLinkLesson
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-3",
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.validateCopyLesson(true)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("linkLessonsLabel"))
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.copyFromType.code == "U" && _vm.copyToClass
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-3",
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.validateCopyUnits(true)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("linkUnitLabel")))]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "d-flex flex-nowrap mx-2" },
            [
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        !_vm.$vuetify.breakpoint.smAndDown || _vm.copySide == 0,
                      expression:
                        "!$vuetify.breakpoint.smAndDown || copySide == 0"
                    }
                  ],
                  attrs: { cols: _vm.$vuetify.breakpoint.smAndDown ? 12 : 6 }
                },
                [
                  _vm.copyFromType.code == "L"
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0" },
                        [
                          _c(
                            "v-col",
                            [
                              _c("copy-lesson-list", {
                                ref: "copyFromLessonlist",
                                attrs: {
                                  items: _vm.copyFromItems,
                                  showDate: _vm.copyFromBank == null,
                                  inputType: _vm.copyFromType.code,
                                  allClasses: _vm.fromAllClasses
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm.copyFromType.code == "U"
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0" },
                        [
                          _c(
                            "v-col",
                            [
                              _c("copy-unit-list", {
                                ref: "copyFromUnitlist",
                                attrs: {
                                  items: _vm.copyFromItems,
                                  lessons: _vm.unitLessons,
                                  allClasses: _vm.fromAllClasses
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("copy-list", {
                                ref: "copyFromList",
                                attrs: {
                                  items: _vm.copyFromItems,
                                  itemType: _vm.copyFromType.code,
                                  isSelectable: true,
                                  allClasses: _vm.fromAllClasses
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        !_vm.$vuetify.breakpoint.smAndDown || _vm.copySide == 1,
                      expression:
                        "!$vuetify.breakpoint.smAndDown || copySide == 1"
                    }
                  ],
                  attrs: { cols: _vm.$vuetify.breakpoint.smAndDown ? 12 : 6 }
                },
                [
                  _vm.copyFromType.code == "L" || _vm.copyFromType.code == "U"
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0" },
                        [
                          _c(
                            "v-col",
                            [
                              _c("copy-lesson-list", {
                                ref: "copyToLessonList",
                                attrs: {
                                  items: _vm.copyToItems,
                                  showDate: _vm.copyToBank == null,
                                  copyFrom: false,
                                  allClasses: _vm.toAllClasses
                                },
                                on: {
                                  dragDropUnit: _vm.confirmUnitDragEvent,
                                  dragDropLesson: _vm.confirmLessonDragEvent
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("copy-list", {
                                ref: "copyToList",
                                attrs: {
                                  items: _vm.copyToItems,
                                  itemType: _vm.isCopyingToLessonBank
                                    ? "LB"
                                    : _vm.copyFromType.code,
                                  isSelectable: false,
                                  allClasses: _vm.toAllClasses
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "pb-base-modal",
        {
          attrs: {
            maxWidth: 500,
            expandable: false,
            withActions: false,
            withApply: false
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("messageLabel")))])
                  ])
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.dialog.importError,
            callback: function($$v) {
              _vm.$set(_vm.dialog, "importError", $$v)
            },
            expression: "dialog.importError"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _c("div", [
                  _c("span", [_vm._v(_vm._s(_vm.$t("errorMessage32")))])
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.dialog.importError = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("okLabel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "pb-base-modal",
        {
          attrs: { maxWidth: 500, expandable: false, withActions: false },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("collabTeacherLabel")))])
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "actions",
              fn: function() {
                return [
                  _vm.editingTeacher
                    ? _c(
                        "pb-btn",
                        {
                          attrs: { label: _vm.$t("deleteLabel"), icon: "" },
                          on: { click: _vm.deleteTeacher }
                        },
                        [_c("v-icon", [_vm._v("fal fa-trash")])],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "pb-btn",
                    {
                      attrs: { label: _vm.$t("saveLabel"), icon: "" },
                      on: { click: _vm.saveTeacher }
                    },
                    [_c("v-icon", [_vm._v("fal fa-check")])],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.dialog.addTeacher,
            callback: function($$v) {
              _vm.$set(_vm.dialog, "addTeacher", $$v)
            },
            expression: "dialog.addTeacher"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    { ref: "addTeacherForm" },
                    [
                      _vm.addTeacherError != ""
                        ? _c(
                            "div",
                            { staticClass: "mx-3" },
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    dense: "",
                                    border: "left",
                                    type: "error"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.addTeacherError) + " ")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-text-field", {
                        staticClass: "pa-3",
                        attrs: {
                          label: _vm.$t("teacherEmailLabel"),
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          rules: [
                            function(v) {
                              return !!v || _vm.$t("collaborateErrorMsg1")
                            }
                          ],
                          "validate-on-blur": ""
                        },
                        model: {
                          value: _vm.addTeacherEmail,
                          callback: function($$v) {
                            _vm.addTeacherEmail = $$v
                          },
                          expression: "addTeacherEmail"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "pa-3",
                        attrs: {
                          label: _vm.$t("teacherKeyLabel"),
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          rules: [
                            function(v) {
                              return !!v || _vm.$t("collaborateErrorMsg2")
                            }
                          ],
                          "validate-on-blur": ""
                        },
                        model: {
                          value: _vm.addTeacherKey,
                          callback: function($$v) {
                            _vm.addTeacherKey = $$v
                          },
                          expression: "addTeacherKey"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }