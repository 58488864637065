import PlanbookDraggable from './planbook-draggable';

export default class LessonDraggable extends PlanbookDraggable {
  data = null;
  constructor(element, options) {
    options = options || {};
    super(
      element,
      options.options || {},
      options.listeners || {}
    );
    this.data = options.data;
  }
}
